import Glide from '@glidejs/glide';

const glides = document.querySelectorAll('[data-behaviour="init-glide"]');

if (glides.length > 0) {
    glides.forEach((glide) => {
        const glideInstance = new Glide(glide, {
            type: glide.dataset.type ? glide.dataset.type : 'slider',
            bound: glide.dataset.bound ? glide.dataset.bound : 0,
            rewind: glide.dataset.rewind ? glide.dataset.rewind : 0,
            gap: glide.dataset.gap ? glide.dataset.gap : 0,
            autoplay: glide.dataset.autoplay ? glide.dataset.autoplay : 0,
            hoverpause: glide.dataset.hoverpause ? glide.dataset.hoverpause : 0,
            animationDuration: 400,
            animationTimingFunc: 'ease-in-out',
            perView: glide.dataset.itemsDesktop
                ? glide.dataset.itemsDesktop
                : 1,
            breakpoints: {
                600: {
                    perView: glide.dataset.itemsMobile
                        ? glide.dataset.itemsMobile
                        : 1,
                    peek: {
                        before: glide.dataset.peekBefore
                            ? glide.dataset.peekBefore
                            : 0,
                        after: glide.dataset.peekAfter
                            ? glide.dataset.peekAfter
                            : 0,
                    },
                },
                940: {
                    perView: glide.dataset.itemsTablet
                        ? glide.dataset.itemsTablet
                        : 1,
                },
                1040: {
                    perView: glide.dataset.itemsDesktop
                        ? glide.dataset.itemsDesktop
                        : 1,
                },
            },
        });
        // Automated height on Carousel build
        glideInstance.on('build.after', function() {
            glideHandleHeight();
        });

        // Automated height on Carousel change
        glideInstance.on('run.after', function() {
            glideHandleHeight();
        });

        // Mount!
        glideInstance.mount();

        // Resize height
        function glideHandleHeight() {
            const activeSlide = document.querySelector('.glide__slide--active');
            const activeSlideHeight = activeSlide
                ? activeSlide.offsetHeight
                : 0;

            const glideTrack = document.querySelector('.glide__track');
            const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

            console.log('glideTrackHeight', glideTrackHeight);
            if (activeSlideHeight !== glideTrackHeight) {
                glideTrack.style.height = `${activeSlideHeight}px`;
            }
        }
    });
}
